<template>
  <b-sidebar
    id="opened-value-form"
    :visible="openedValueForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-value-form', val)"
    @shown="loadAttr()"
  >
    <template>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('form.value.label') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card
        v-if="item"
        class="card-value"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              :label="$t('form.type.label')"
              label-for="key"
            >
              <v-select
                v-model="item.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="paymentGatewayTypes"
                :reduce="val => val.value"
                :clearable="false"
                :state="errors && errors.type ? false : null"
              />
              <b-form-invalid-feedback v-if="errors && errors.type">
                {{ errors.type[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('form.bank.label')"
              label-for="key"
            >
              <v-select
                v-model="item.bank"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="paymentGatewayBanks"
                :reduce="val => val.value"
                :clearable="false"
                :state="errors && errors.bank ? false : null"
              />
              <b-form-invalid-feedback v-if="errors && errors.bank">
                {{ errors.bank[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="`API Key`"
              label-for="api_key"
            >
              <b-form-input
                :id="'api_key'"
                v-model="item.api_key"
                :state="errors && errors.api_key ? false : null"
              />
              <b-form-invalid-feedback v-if="errors && errors.api_key">
                {{ errors.api_key[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="`API Password`"
              label-for="api_password"
            >
              <b-form-input
                :id="'api_password'"
                v-model="item.api_password"
                :state="errors && errors.api_password ? false : null"
              />
              <b-form-invalid-feedback v-if="errors && errors.api_password">
                {{ errors.api_password[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('form.markup.label')"
              label-for="markup"
            >
              <b-form-input
                :id="'markup'"
                v-model="item.markup"
                type="number"
                min="0"
                max="99"
                step="0.01"
                :state="errors && errors.markup ? false : null"
              />
              <b-form-invalid-feedback v-if="errors && errors.markup">
                {{ errors.markup[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('form.position.label')"
              label-for="position"
            >
              <b-form-input
                :id="'position'"
                v-model="item.position"
                type="number"
                min="0"
                step="1"
                :state="errors && errors.position ? false : null"
              />
              <b-form-invalid-feedback v-if="errors && errors.position">
                {{ errors.position[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('form.min_parts.label')"
              label-for="min_parts"
            >
              <b-form-input
                :id="'min_parts'"
                v-model="item.min_parts"
                type="number"
                min="0"
                step="1"
                :state="errors && errors.min_parts ? false : null"
              />
              <b-form-invalid-feedback v-if="errors && errors.min_parts">
                {{ errors.min_parts[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('form.max_parts.label')"
              label-for="max_parts"
            >
              <b-form-input
                :id="'max_parts'"
                v-model="item.max_parts"
                type="number"
                min="0"
                step="1"
                :state="errors && errors.max_parts ? false : null"
              />
              <b-form-invalid-feedback v-if="errors && errors.max_parts">
                {{ errors.max_parts[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!-- media -->
            <b-media no-body>
              <b-media-aside>
                <b-link>
                  <b-img
                    ref="previewEl"
                    rounded
                    :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                    height="80"
                  />
                </b-link>
                <!--/ avatar -->

                <!-- reset -->
                <b-button
                  v-if="item.avatar"
                  variant="outline-secondary"
                  size="sm"
                  class="mb-75 mr-75"
                  @click="item.avatar = null"
                >
                  {{ $t('general.reset') }}
                </b-button>
                <!--/ reset -->
              </b-media-aside>

              <b-media-body class="mt-75 ml-75">
                <InitFileManager
                  field="avatar"
                  type="payment_method_avatar"
                  :multiple="false"
                  @fm-selected="selectedAvatar"
                />

                <b-row
                  v-if="errors && errors.avatar"
                >
                  <b-col cols="12">
                    <b-form-invalid-feedback :state="false">
                      {{ errors.avatar[0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row
                  v-if="errors && errors['avatar.type']"
                >
                  <b-col cols="12">
                    <b-form-invalid-feedback :state="false">
                      {{ errors['avatar.type'][0] }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>

              </b-media-body>
            </b-media>
            <!--/ media -->
          </b-col>
        </b-row>

        <b-tabs>
          <b-tab
            v-for="language in languages"
            :key="language.code"
          >
            <template #title>
              <b-img
                :src="require('@/assets/images/flags/' + language.code + '.svg')"
                height="16"
                width="16"
                class="mr-1"
              />
              <span class="d-none d-sm-inline">{{ language.title }}</span>
            </template>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="$t('form.title.label')"
                  :label-for="'item.title.' + language.code"
                >
                  <b-form-input
                    :id="'item.title.' + language.code"
                    v-model="item.title[language.code]"
                    :state="errors && errors.title && errors.title[language.code] ? false : null"
                  />
                  <b-form-invalid-feedback v-if="errors && errors['item.title.' + language.code]">
                    {{ errors.title[language.code][0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="$t('form.description.label')"
                  :label-for="'item.description.' + language.code"
                >
                  <b-form-textarea
                    :id="'item.description.' + language.code"
                    v-model="item.description[language.code]"
                    :state="errors && errors.description && errors.description[language.code] ? false : null"
                  />
                  <b-form-invalid-feedback v-if="errors && errors['item.description.' + language.code]">
                    {{ errors.description[language.code][0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              block
              @click="submit"
            >
              {{ $t('general.save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import _ from 'lodash'
import GlobalMixin from '@mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin],
  model: {
    prop: 'openedValueForm',
    event: 'update:opened-value-form',
  },
  props: {
    openedValueForm: {
      type: Boolean,
      required: true,
    },
    parentId: {
      type: Number,
      required: true,
    },
    valueId: {
      type: Number,
      required: true,
    },
    languages: {
      type: [Array],
      default: null,
    },
  },
  data() {
    return {
      item: null,
    }
  },
  methods: {
    async loadAttr() {
      if (this.valueId) {
        await this.$http.get(`/api/admin/payment_methods/${this.parentId}/gateways/${this.valueId}`)
          .then(response => {
            const { data } = response.data
            this.item = this.transformData(data)
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.item = undefined
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('notifications.not_found'),
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
            }
          })
      } else {
        this.item = this.transformData(null)
      }
    },
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-value-form', false)
      this.item = null
    },
    selectedAvatar(data) {
      this.item.avatar = data
      this.$forceUpdate()
    },
    submit() {
      if (!this.valueId) {
        this.$http.post(`/api/admin/payment_methods/${this.parentId}/gateways/`, this.item)
          .then(() => {
            this.$store.dispatch('validation/clearErrors')
            this.$emit('refetch-data')
            this.$emit('update:opened-value-form', false)
            this.item = null
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      } else {
        this.$http.put(`/api/admin/payment_methods/${this.parentId}/gateways/${this.valueId}`, this.item)
          .then(() => {
            this.$store.dispatch('validation/clearErrors')
            this.$emit('refetch-data')
            this.$emit('update:opened-value-form', false)
            this.item = null
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }
    },
    transformData(item) {
      let data = {}
      if (item) {
        data = {
          bank: item.bank,
          type: item.type,
          markup: item.markup,
          position: item.position,
          min_parts: item.min_parts,
          max_parts: item.max_parts,
          api_key: item.api_key,
          api_password: item.api_password,
          avatar: this.$options.filters.singleMedia(item.avatar),
          title: {},
          description: {},
        }
        _.each(this.languages, language => {
          const translation = _.find(item.translations, { locale: language.code })
          data.title[language.code] = translation ? translation.title : null
          data.description[language.code] = translation ? translation.description : null
        })
      } else {
        data = {
          bank: 'cash',
          type: 'cash',
          markup: 0,
          position: 0,
          min_parts: 0,
          max_parts: 0,
          avatar: null,
          api_key: null,
          api_password: null,
          title: {},
          description: {},
        }
        _.each(this.languages, language => {
          data.title[language.code] = null
          data.description[language.code] = null
        })
      }
      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-value-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
